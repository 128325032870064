import {createSelector} from 'reselect';
import intersectionBy from 'lodash/intersectionBy';
import isBoolean from 'lodash/isBoolean';
import extend from 'lodash/extend';
import groupBy from 'lodash/groupBy';
import Constants, {
  DATE_TIME_FORMATTERS,
  DEFAULT_DISCLAIMER_KEYS,
  DISCLAIMER_KEYS_FOR_PAGES,
  FAQ_GLOSSARY_KEYS,
  STATIC_DISCLAIMER_KEYS
} from '../../constants/appConstants';
import {
  allowFeaturesSelector,
  getUserTimeZone,
  isClearWaterSSOEnabledSelector,
  jurisdictionSelector,
  userIdSelector
} from '../user';
import {isLogoutFailedSelector, pageIdSelector} from '../pages';
import translator from '../../services/translator';
import {getNavConfigForPageId} from '../../containers/TradeActivity/TradeApprovals/helper';
import {formatDateToUserTimezone} from '../../utils/dateFormatter';
import {getWindowLocationValueByProp, isMosaic, removeUnwantedTags, updateCurrentYear} from '../../utils/commonUtils';

const {translate: t} = translator;

const defaultNavRootConfig = {navRootConfig: []};
const defaultFirms = [];

export const defaultMessages = [];
export const defaultMessageCount = {
  [Constants.MESSAGE_PRIORITY_IMPORTANT]: 0,
  [Constants.MESSAGE_PRIORITY_CRITICAL]: 0,
  [Constants.MESSAGE_PRIORITY_NORMAL]: 0
};

export const envSelector = state => state.appContext && state.appContext.NODE_ENV;
export const getLabel = (state, key) => (state.appData.labels && state.appData.labels[key]) ? state.appData.labels[key] : '';
export const labelSelector = state => state.appData.labels || {};
export const configurableConstantsSelector = state => state.appData.configurableConstants || {};
export const allowedDomainSelector = state => state.appData.configurableConstants ? state.appData.configurableConstants.ALLOWED_DOMAINS : [];
export const historySelector = state => state.appContext.history;
export const navBar = state => state.appData.navBar || defaultNavRootConfig;
export const jurisdictionDisclaimerSelector = state => state.appData.jurisdictionDisclaimer;
export const rawStaticDisclaimersSelector = state => state.appData.staticDisclaimers;
export const faqAndGlossaryContentsSelector = state => state.appData.faqGlossaryContents || [];
export const profileHostMappingSelector = state => state.appData.profileHostMappings || [];
export const userProfileDetailSelector = state => state.appData.userProfileDetails || {};
export const isAppLoadingSelector = state => state.appContext.isAppLoading;
export const isAppInitialLoadFlagSelector = state => (state.appContext.isAppInitialLoad === undefined) ? true : state.appContext.isAppInitialLoad;
export const isLogoutInProgressSelector = state => state.appContext.isLogoutInProgress;
export const draftTradesSelector = state => state.appContext.draftTrades;
export const hasBatchDelay = state => state.appContext.batchDelay && state.appContext.batchDelay.showBatchDelayMessage;
export const isTradeSaveFailed = state => state.appContext.tradeSaveFailed;
export const firmsSelector = state => state.appData.firms || defaultFirms;
export const reportsSelectedFirmsSelector = state => state.appData.reports && state.appData.reports.selectedFilterData ? state.appData.reports.selectedFilterData.firmIds : [];
export const hasPendingTradesForApproval = state => state.appContext.hasPendingTradesForApproval || false;
export const userLastLoginTime = state => state.appContext.previousLogin;
export const shareClassFromFundFinderOrPreferenceSelector = state => state.appContext.fundTrackerInitialShareClassData;
export const fundTrackerGridConfigsSelector = state => state.appContext.fundTrackerGridConfigs;
export const fundIdentifierPreference = state => state.preferences && state.preferences.global && state.preferences.global.fundIdentifier || '';

// to be used for update API
export const appDataMessagesSelector = state => state.appData.messages || defaultMessages;
export const appContextMessagesSelector = state => state.appContext.messages || defaultMessages;
export const regionsSelector = state => state.appData.regions;
export const helpContentsSelector = state => state.appData.helpContents;
export const isSessionExpiredFlagSelector = state => state.appContext.isSessionExpired || false;
export const stopPollingTickerApiSelector = state => state.appContext.stopPollingTickerApi || false;
export const pathSelector = state => state.appData.pathMapping;
export const usernameSelector = state => state.appContext.username;
export const pwdSelector = state => state.appContext.loginPwd;
export const reportingPermitRolesSelector = state => state.appContext.reportingPermitRoles || [];
export const financialConditionsLinkSelector = state => state.appData.loginConfiguration && state.appData.loginConfiguration.financialConditionsLink;
export const currencySymbolSelector = state => state.appData.currencySymbol || {};
export const currencyLabelSelector = state => state.appData.currencyLabel || {};
export const selectedFirmsSelector = state => state.preferences && state.preferences.global && state.preferences.global.selectedFirms || [];
export const isMimicSessionSelector = state => state.appContext && state.appContext.mimicInfo && state.appContext.mimicInfo.isMimicSession || false;
export const isAllowSaveChangesOnMimicSelector = state => state.appContext && state.appContext.mimicInfo && state.appContext.mimicInfo.isAllowSaveChangesOnMimic;

export const navBarSelector = createSelector(
  navBar,
  (navBar) => {
    const env = document.body.getAttribute('data-sys-env') || '';
    const {navRootConfig = []} = navBar;
    navRootConfig.forEach((nav) => {
      if (nav.nonProdURL && (env.toLowerCase() !== Constants.PROD.toLowerCase())) {
        nav.URL = nav.nonProdURL;
      }
      return nav;
    });
    return navBar;
  }
);

export const isUserProfileMatchingSelector = createSelector(
  profileHostMappingSelector,
  userProfileDetailSelector,
  (profileHostMapping = {}, userProfileDetail) => {
    const loggedInHost = getWindowLocationValueByProp(Constants.HOSTNAME);
    const {profileNames = [], hosts = []} = profileHostMapping;
    const {profileName: userSiteProfileName = ''} = userProfileDetail;
    return hosts.includes(loggedInHost) ? profileNames.includes(userSiteProfileName) : true;
  }
);

export const faqGlossaryPageLoadEventSelector = createSelector(
  configurableConstantsSelector,
  pageIdSelector,
  (pageHierarchy = [], pageId) => {
    const analyticsMetaData = pageHierarchy[Constants.ANALYTICS_PAGE_HIERARCHY] || [];
    const pageSectionLabels = analyticsMetaData[pageId] || [];
    const pageName = pageSectionLabels.join(':');
    const [section, subSection = ''] = pageSectionLabels;
    return {
      page: {
        pageInfo: {
          pageName,
          section,
          subSection
        }
      }
    };
  }
);

export const contactUsLinkSelector = createSelector(
    navBarSelector,
    (navBar) => (navBar && navBar.navRootConfig.find(data => data.resourceName === Constants.RESOURCE_NAME_CONTACT_US))
);

export const unreadServerMessageseSelector = createSelector(
    appDataMessagesSelector,
    serverSideMessages => {
        return serverSideMessages && serverSideMessages.filter(message => message.status === Constants.MESSAGE_STATUS_UNREAD).length;
    }
);

export const unreadMessagesToUpdateSelector = createSelector(
    appContextMessagesSelector,
    clientSideMessages => {
        return clientSideMessages && clientSideMessages.filter(message => message.status === Constants.MESSAGE_STATUS_UNREAD).length;
    }
);

export const analyticsMessagePayloadSelector = createSelector(
    appContextMessagesSelector,
    messages => {
        return messages.map(message => {
            const {messageId, status, priority} = message;
            return {messageId, status, priority};
        });
    }
);

export const messageCenterUpdatePayloadSelector = createSelector(
    appContextMessagesSelector,
    userIdSelector,
    (messages, userId) => {
        const trimmedMessages = messages.map(message => {
            const {messageId, status} = message;
            return {messageId, status};
        });

        return {
            id: userId,
            messages: trimmedMessages
        };
    }
);

export const prioritizedMessageCountSelector = createSelector(
    appContextMessagesSelector,
    (messages) => {
        const unreadMessagesCountByPriority = {...defaultMessageCount};
        messages
            .filter(message => message.status === Constants.MESSAGE_STATUS_UNREAD)
            .forEach(unreadMessage => {
                unreadMessagesCountByPriority[unreadMessage.priority]++;
            });
        return unreadMessagesCountByPriority;
    }
);

export const unreadMessagesCountSelector = createSelector(
    prioritizedMessageCountSelector,
    ({IMPORTANT = 0, CRITICAL = 0, NORMAL = 0}) => {
        return IMPORTANT + CRITICAL + NORMAL;
    }
);

export const criticalMessagesCountSelector = createSelector(
    prioritizedMessageCountSelector,
    ({CRITICAL = 0}) => {
        return CRITICAL;
    }
);

// We are using `reverse` or LIFO because Critical Message Modal requires oldest messages to be displayed first
export const unreadCriticalMessagesLifoSelector = createSelector(
    appContextMessagesSelector,
    (appContextMessages) => {
        return appContextMessages
            .filter(message => message.status === Constants.MESSAGE_STATUS_UNREAD &&
                message.priority === Constants.MESSAGE_PRIORITY_CRITICAL)
            .reverse();
    }
);

export const showFirmFilterSelector = createSelector(
    pageIdSelector,
    navBarSelector,
    (pageId, navBar) => {
        const navConfigs = navBar.navRootConfig || [];
        const pageNavConfig = getNavConfigForPageId(navConfigs, pageId);
        return pageNavConfig && pageNavConfig.showFirmFilter;
    }
);

export const showMessageCenterSelector = createSelector(
  allowFeaturesSelector,
  (allowFeatures) => (
    !!(allowFeatures.length && allowFeatures.includes(Constants.MESSAGECENTER))
  )
);

export const sortedNavLinksSelector = createSelector(
    navBarSelector,
    hasPendingTradesForApproval,
    (navBarConfigs, hasTrades) => {
        const {navRootConfig = []} = navBarConfigs;
        const filteredPrimaryNav = navRootConfig.filter(nav => nav.isPrimaryNav)
            .map(nav => (nav.routingPathId === Constants.TRADE_ACTIVITY && hasTrades) ?
                {...nav, alertClass: 'nav-bar__link-alert'} : nav);
        return [...filteredPrimaryNav].sort((a, b) => a.order - b.order);
    }
);

export const secNavLinksSelector = createSelector(
  navBarSelector,
  isClearWaterSSOEnabledSelector,
  (navBarConfigs, isClearWaterSSOEnabled) => {
    const { navRootConfig } = navBarConfigs;
    const filteredSecondaryNav = navRootConfig.filter(nav => nav.isSecondaryNav);
    const groupedData = groupBy(filteredSecondaryNav, 'groupIndex');
    const secNavData = [];
    Object.keys(groupedData).sort().forEach(key => {
      if (groupedData[key]) {
        groupedData[key].sort((a, b) => a.order - b.order);
        groupedData[key].forEach(item => {
          if (item.displayNameKey === 'tkNavClearwaterAnalytics' && isClearWaterSSOEnabled) {
            item.isClearWaterSSOEnabled = true;
          }
        });
      }
      secNavData.push({ navigationLinks : groupedData[key]});
    });
    return secNavData;
  }
);

export const getContactUsURL = createSelector(
    navBarSelector,
    (navBarConfigs) => {
        const {navRootConfig} = navBarConfigs;
        const item = navRootConfig.find(nav => (nav.resourceName === Constants.RESOURCE_NAME_CONTACT_US));
        return item && item.URL;
    }
);

export const firmsFilterSelector = createSelector(
    firmsSelector,
    selectedFirmsSelector,
    (firms, selectedFirms) => {
        const allfirms = firms.map(firm => ({
                label: firm.name ? `${firm.name} - ${firm.firmCode}` : `${firm.firmCode}`,
                value: firm.firmId,
                isSelected: selectedFirms.includes(firm.firmId)
            })
        );
        const firmsSorted = [...allfirms].sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            } else return 0;
        });
        return [...firmsSorted];
    }
);

export const firmsAllSelector = createSelector(
    firmsSelector,
    selectedFirmsSelector,
    (firms, selectedFirms) => {

        const all = {
            label: t('tkAllFirms'),
            value: t('tkAll'),
            isSelected: firms.length === selectedFirms.length
        };
        return [all];
    }
);

export const selectedFirms = createSelector(
    firmsSelector,
    selectedFirmsSelector,
    (firms, selectedFirms) => firms.filter(firm => selectedFirms.includes(firm.firmId))
);

export const reportsSelectedFirms = createSelector(
    firmsSelector,
    reportsSelectedFirmsSelector,
    (firms, reportsSelectedFirms) => firms.filter(firm => reportsSelectedFirms.includes(firm.firmId))
);

export const firmFilterMessageSelector = createSelector(
    selectedFirms,
    labelSelector,
    (selectedOptions, labels) => {
        if (!selectedOptions.length) return '';
        else if (selectedOptions.length === 1) return selectedOptions[0].name;
        else return `${labels.FIRM_SEARCH} [${selectedOptions.length}]`;
    }
);

export const allFirmFilterMessageSelector = createSelector(
    selectedFirms,
    firmsSelector,
    labelSelector,
    (selectedOptions, firms, labels) => {

        if (!selectedOptions.length) return `${t('tkAllFirms')}`;
        else if (selectedOptions.length === 1) return selectedOptions[0].name;
        else if (selectedOptions.length !== firms.length) return `${labels.FIRM_SEARCH} [${selectedOptions.length}]`;
        else if (selectedOptions.length === firms.length) return `${t('tkAllFirms')}`;
        else return `${labels.FIRM_SEARCH} [${selectedOptions.length}]`;
    }
);

export const selectedFirmsList = createSelector(
    selectedFirmsSelector,
    (selectedFirms) => selectedFirms.map(firmId => ({firmId}))
);

export const shareHolderContactInfoSelector = createSelector(
    regionsSelector,
    jurisdictionSelector,
    (regions = [], jurisdiction) => {
        let data = regions.find(region => region.JurisdictionList.includes(jurisdiction));
        if (!data) {
            data = regions.find(region => region.JurisdictionList.includes('US'));
        }
        return data;
    }
);

export const staticDisclaimersSelector = createSelector(
  rawStaticDisclaimersSelector,
  (disclaimers) => {
    if (disclaimers) {
      const disclaimerContents = {};
      STATIC_DISCLAIMER_KEYS.forEach(key => {
        const {text = ""} = disclaimers[key] || {};
        disclaimerContents[key] = text;
      });
      return disclaimerContents;
    }
    return null;
  }
);

export const defaultDisclaimersSelector = createSelector(
  staticDisclaimersSelector,
  (staticDisclaimers) => {
    const defaultDisclaimers = {links: ''};
    if (staticDisclaimers) {
      DEFAULT_DISCLAIMER_KEYS.forEach(key => {
        defaultDisclaimers[key] = staticDisclaimers[key]
      });
    }
    return defaultDisclaimers;
  }
);

export const termsAndConditionsDataVersionSelector = createSelector(
  staticDisclaimersSelector,
  (staticDisclaimers) => {
    const {termsAndConditionsDataVersion = "1"} = staticDisclaimers;
    return removeUnwantedTags(termsAndConditionsDataVersion);
  }
);

export const faqGlossaryContentsSelector = createSelector(
  pageIdSelector,
  faqAndGlossaryContentsSelector,
  allowFeaturesSelector,
  (pageId, faqGlossaryContents = {}, allowFeatures) => {
    let finalContents = [];
    const contents = faqGlossaryContents[`${pageId.toLowerCase()}Data`];
    if (contents) {
      finalContents = removeUnwantedTags(contents.text);
      finalContents = finalContents.replace(/\[mifidiiDocUpdateYear]/, FAQ_GLOSSARY_KEYS.MIFIDII_DOC_UPDATE_YEAR);
      finalContents = JSON.parse(finalContents);
      if (pageId === FAQ_GLOSSARY_KEYS.FAQ) {
        return finalContents.map(({section, id, items}) => ({
            section,
            id,
            items: items.filter(item => !item.permission || allowFeatures.includes(item.permission))
          })
        );
      } else if (pageId === FAQ_GLOSSARY_KEYS.GLOSSARY) {
        const {items} = finalContents.find(item => item.section === pageId);
        return items;
      }
    }
    return finalContents;
  }
);

export const termsAndConditionContentsSelector = createSelector(
  staticDisclaimersSelector,
  labelSelector,
  (staticDisclaimers, {tkCopyRightFooterTextWithLogo = ""}) => {
    if (staticDisclaimers) {
      const tncData = removeUnwantedTags(staticDisclaimers.termsAndConditionsData)
      /* Below copyright footer text (tkCopyRightFooterTextWithLogo) only set for Huntington in en.json
         for now as for Huntington there is a logo, for other clients it's empty */
      const additionalCopyRightFooter = tkCopyRightFooterTextWithLogo || "";
      return updateCurrentYear([tncData, additionalCopyRightFooter].join(''));
    }
    return "";
  }
);

export const profileDisclaimerSelector = createSelector(
  jurisdictionDisclaimerSelector,
  (disclaimers = {}) => {
    const {countryDisclaimerData: {text = ""} = {}} = disclaimers;
    return {countryDisclaimerData: text};
  }
);

export const pageDisclaimersSelector = createSelector(
  staticDisclaimersSelector,
  pageIdSelector,
  (staticDisclaimers, pageId) => {
    if (staticDisclaimers && pageId) {
      const disclaimerKeys = [];
      Object.entries(DISCLAIMER_KEYS_FOR_PAGES)
        .forEach(([key, values]) => {
          if (values.includes(pageId)) {
            disclaimerKeys.push(key);
          }
        });
      const disclaimerData = disclaimerKeys
        .map(key => staticDisclaimers[key]);
      return [...disclaimerData];
    }
    return [];
  }
);

const allViewsSelector = state => state.pageContext.views || [];
const columnsMetadata = state => state.pageData.columnsMetadata && state.pageData.columnsMetadata.columns || [];
const getCurrentGridView = createSelector(
    allViewsSelector,
    columnsMetadata,
    (allViews, metadata) => {
        const viewColumns = allViews.filter(view => view.isActive).length ? allViews.filter(view => view.isActive)[0] : {};
        const {columns = []} = viewColumns;
        if (columns) {
            const intersectionColumns = intersectionBy(columns, metadata, 'field');
            return intersectionColumns.map((column) => {
                const metadataObject = metadata.find(data => data.field === column.field);
                return extend({}, metadataObject, column);
            });
        }
        return [];
    }
);
export const gridColumnDisclaimersSelector = createSelector(
  getCurrentGridView,
  staticDisclaimersSelector,
  (currentViewColumns, staticDisclaimers) => {
    const disclaimers = [];
    if (staticDisclaimers) {
      currentViewColumns.forEach(column => {
        const {disclaimerLabel = ''} = column;
        const disclaimer = disclaimerLabel && removeUnwantedTags(staticDisclaimers[disclaimerLabel]);
        if (disclaimer) {
          disclaimers.push(`<p><b>* ${disclaimer}</b></p>`);
        }
      });
    }
    return disclaimers.join('');
  }
);

export const isFooterDisclaimerSelector = (state, isFooterDisclaimer) => (
  (isBoolean(isFooterDisclaimer) && isFooterDisclaimer)
);

export const additionalDisclaimersSelector = createSelector(
  staticDisclaimersSelector,
  labelSelector,
  isFooterDisclaimerSelector,
  (staticDisclaimers = {}, labels, isFooterDisclaimer) => {
    const disclaimerList = [];
    if (staticDisclaimers && labels) {
      disclaimerList.push('<br/>');
      const {tkCopyRightFooterTextWithLogo, tkCopyRightFooterTextWithoutLogo} = labels;
      const gsOffShoreFundsDisclaimerData = staticDisclaimers?.gsOffShoreFundsDisclaimerData ?? "";
      const termsAndConditionsData = staticDisclaimers?.termsAndConditionsData ?? "";
      // Below copyright footer text only set for Huntington for now as for Huntington there is a logo, for other clients it's empty
      const copyRightFooter = (isFooterDisclaimer ? tkCopyRightFooterTextWithLogo : tkCopyRightFooterTextWithoutLogo) || "";
      const termsAndConditionsDisclaimer = [termsAndConditionsData, copyRightFooter].join('');
      [termsAndConditionsDisclaimer, gsOffShoreFundsDisclaimerData].forEach(item => {
        const updatedItem = updateCurrentYear(item);
        disclaimerList.push(updatedItem);
      });
      disclaimerList.push(staticDisclaimers.gsOffShoreFundsDisclaimerData);
    }
    return disclaimerList.filter(item => item);
  }
);

export const reportDisclaimerKeysSelector = createSelector(
  jurisdictionDisclaimerSelector,
  pageIdSelector,
  (jurisdictionDisclaimer, pageId) => {
    if (jurisdictionDisclaimer && pageId) {
      const keys = [
        'globalDisclaimerStaticData', 'separator', 'globalDisclaimerDynamicData',
        'DisclaimerData', 'countryDisclaimerData'
      ].map(key => {
        if(!key.includes('global') && !key.includes('country') && !key.includes('separator')) {
          return `${pageId.toLowerCase()}${key}`;
        } else {
          return key;
        }
      });
      keys.push('excelOnlyDisclaimer');
      return keys;
    }
    return [];
  }
);

export const reportDisclaimerSelector = createSelector(
  staticDisclaimersSelector,
  profileDisclaimerSelector,
  pageIdSelector,
  reportDisclaimerKeysSelector,
  gridColumnDisclaimersSelector,
  additionalDisclaimersSelector,
  (
    staticDisclaimers, jurisdictionDisclaimer, pageId,
    reportDisclaimerKeys, gridColumnDisclaimers, additionalDisclaimers
  ) => {
    if (staticDisclaimers && jurisdictionDisclaimer && pageId  && reportDisclaimerKeys.length > 0) {
      const reportDisclaimers = [gridColumnDisclaimers];
      const otherDisclaimers = {...staticDisclaimers, ...jurisdictionDisclaimer, separator: '  '};
      const allDisclaimers = reportDisclaimerKeys.map(key => otherDisclaimers[key]).filter(item => item);
      allDisclaimers && reportDisclaimers.push(...allDisclaimers);
      additionalDisclaimers && reportDisclaimers.push(...additionalDisclaimers);
      if (isMosaic()) {
        reportDisclaimers.push(updateCurrentYear(staticDisclaimers.copyRightData));
      }
      return reportDisclaimers.filter(item => item);
    }
    return [];
  }
);

export const reportDisclaimerKeysObjectSelector = createSelector(
  staticDisclaimersSelector,
  reportDisclaimerKeysSelector,
  getCurrentGridView,
  (staticDisclaimers, reportDisclaimerKeys, viewColumns) => {
    if (staticDisclaimers) {
      const separator = {text: '  '};
      const mainDisclaimerKeys = reportDisclaimerKeys
        .filter(key => (key !== 'countryDisclaimerData'))
        .map(key => ({key, type: 'MAIN'}));

      const jurisdictionDisclaimer = [{
        key: 'countryDisclaimerData',
        type: 'MAIN'
      }];

      const copyRightDisclaimer = [{
        key: 'copyRightData',
        type: 'MAIN'
      }];

      const viewDisclaimerKeys = viewColumns
        .map(({disclaimerLabel = ''}) => (
          disclaimerLabel && {key: disclaimerLabel, type: 'VIEW_COLUMNS'}
        ))
        .filter(disclaimer => disclaimer);

      const additionalDisclaimerKeys = [
        {
          key: 'termsAndConditionsData',
          type: 'ADDITIONAL'
        },
        {
          key: 'gsOffShoreFundsDisclaimerData',
          type: 'ADDITIONAL'
        }
      ];
       return {
         viewDisclaimerKeys, mainDisclaimerKeys,
         jurisdictionDisclaimer, additionalDisclaimerKeys,
         copyRightDisclaimer, separator
       };
    }
    return {};
  }
);


export const draftTradesInBlotterSelector = createSelector(
    draftTradesSelector,
    draftTrades => {
        return draftTrades && draftTrades.length && draftTrades.map((draftTrade) => {
            const {account: {id: accountId = '', name: accountName = '', taAccount = ''} = {}, firm: {firmId = '', branchId = ''} = {}, shareClass: {id: shareClassId = ''} = {}} = draftTrade;
            return {
                account: {id: accountId, name: accountName, taAccount},
                firm: {firmId, branchId},
                shareClass: {id: shareClassId}
            };
        }) || [];
    }
);

export const selectedIdsSelector = () => {
    const selectedFirmIds = {};
    const selectedBranchIds = {};
    return {
        selectedFirmIds,
        selectedBranchIds
    };
};

export const snackbarDataList = createSelector(
    labelSelector, hasBatchDelay, isLogoutFailedSelector, (labels, batchDelay, isLogoutFailed) => {
        const list = [];
        if (batchDelay) {
            list.push({
                displayMultiple: true,
                type: 'warning',
                message: labels.tkBatchDelay,
                autoHide: false,
                showCloseCTA: true
            });
        }
        if (isLogoutFailed) {
            list.push({
                displayMultiple: false,
                type: 'error',
                message: labels.tkError500Body,
                autoHide: false,
                showCloseCTA: true
            });
        }

        return list;
    }
);

export const userLastLoginTimeSelector = createSelector(getUserTimeZone, userLastLoginTime, (userTimeZone = {}, userLastLoginTime = {}) => {
    return userLastLoginTime && formatDateToUserTimezone(userLastLoginTime, userTimeZone, DATE_TIME_FORMATTERS.DD_MMM_YYYY_HH_MM_SS_A_Z) || '';
});

export const saveChangesSelector = createSelector(
  isMimicSessionSelector, isAllowSaveChangesOnMimicSelector,
  (isMimicSession, isAllowSaveChangesOnMimic) => {
    return (!isMimicSession || isAllowSaveChangesOnMimic)
});

export default {
    envSelector,
    getLabel,
    labelSelector,
    historySelector
};
