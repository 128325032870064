import Portfolio from '../pages/portfolio';
import FundFinder from '../pages/fundFinder';
import FundTrackerMain from '../pages/fundTracker';
import FundTrackerNew from '../modules/FundTracker/containers';
import Error from '../pages/error';
import Constants from '../constants/appConstants';
import Preferences from '../pages/preferences';
import Trading from '../pages/trading';
import TradeActivity from '../pages/tradeActivity';
import TradeApprovals from '../containers/TradeActivity/TradeApprovals';
import CurrentTrades from '../containers/TradeActivity/CurrentTrades';
import Logout from '../pages/logout';
import DisplaySettings from '../containers/Preferences/Sections/DisplaySettings';
import EmailNotifications from '../containers/Preferences/Sections/EmailNotifications';
import FundTrackerThresholds from '../containers/Preferences/Sections/FundTrackerThresholds';
import InvestmentPolicy from '../containers/Preferences/Sections/InvestmentPolicy';
import Reports from '../pages/reports';
import MyReports from '../containers/Reports/MyReports';
import AllReports from '../containers/Reports/AllReports';
import IndividualReport from '../containers/Reports/IndividualReport';
import StitchedReport from '../containers/Reports/StitchedReport';
import UserSearch from '../modules/admin/UserSearch';
import FAQ from '../containers/FAQ';
import Glossary from '../containers/Glossary';
import BuildInfo from '../containers/BuildInfo';
import {getAppRoute} from '../utils/commonUtils';

export default [
  {
    'exact': true,
    'path': getAppRoute(Constants.PORTFOLIO_ROUTE),
    'component': Portfolio,
    'id': 'Portfolio',
    'resourceName': 'PORTFOLIO'
  },
  {
    'exact': false,
    'path': getAppRoute(Constants.REPORTS_ROUTE),
    'component': Reports,
    'id': 'Reports',
    'skipUpdatePageId': true,
    'subRoutes': [
      {
        'exact': true,
        'path': getAppRoute(Constants.ALL_REPORTS_TAB_ROUTE),
        'component': AllReports,
        'id': 'AllReports',
        'resourceName': 'REPORTING'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_MY_REPORTS_TAB_ROUTE),
        'component': MyReports,
        'id': 'MyReports',
        'resourceName': 'REPORTINGNEW'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.SAVED_REPORT_ROUTE),
        'component': IndividualReport,
        'id': 'SavedReport',
        'resourceName': 'REPORTINGNEW'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_AB_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'ab',
        'resourceName': 'ab.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_HTA_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'hta',
        'resourceName': 'hta.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_CF_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'cf',
        'resourceName': 'cf.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_MDA_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'mda',
        'resourceName': 'mda.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_MSF_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'msf',
        'resourceName': 'msf.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCAL_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'dcal',
        'resourceName': 'dcal.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_GL_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'gl',
        'resourceName': 'gl.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_MCD_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'mcd',
        'resourceName': 'mcd.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_CTS_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'cts',
        'resourceName': 'cts.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_CTTF_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'cttf',
        'resourceName': 'cttf.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_WS_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'ws',
        'resourceName': 'ws.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_HTAE_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'htae',
        'resourceName': 'htae.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCTA_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'dcta',
        'resourceName': 'dcta.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCSS_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'dcss',
        'resourceName': 'dcss.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCFS_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'dcfs',
        'resourceName': 'dcfs.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_FB_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'fb',
        'resourceName': 'fb.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_RF_ROUTE_LEGACY),
        'component': StitchedReport,
        'id': 'rf',
        'resourceName': 'rf.legacy'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_AB_ROUTE),
        'component': IndividualReport,
        'id': 'account-balance',
        'resourceName': 'ab.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_HTA_ROUTE),
        'component': IndividualReport,
        'id': 'historical-transactions',
        'resourceName': 'hta.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_CF_ROUTE),
        'component': IndividualReport,
        'id': 'class-fees',
        'resourceName': 'cf.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_MDA_ROUTE),
        'component': IndividualReport,
        'id': 'monthly-dividend',
        'resourceName': 'mda.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_MSF_ROUTE),
        'component': IndividualReport,
        'id': 'monthly-service',
        'resourceName': 'msf.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCAL_ROUTE),
        'component': IndividualReport,
        'id': 'debit-credit-accounts',
        'resourceName': 'dcal.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_GL_ROUTE),
        'component': IndividualReport,
        'id': 'gain-loss-onshore',
        'resourceName': 'gl.new'
      },
      // TODO: ELSOL-67109 commenting this route due to expected issue in prod. Needs to be reenabled in ELSOL-65591
      // {
      //   'exact': true,
      //   'path': getAppRoute(Constants.REPORT_GL_OFFSHORE_ROUTE),
      //   'component': IndividualReport,
      //   'id': 'gain-loss-offshore',
      //   'resourceName': 'gl.new'
      // },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_MCD_ROUTE),
        'component': IndividualReport,
        'id': 'ex-post-costs',
        'resourceName': 'mcd.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_CTS_ROUTE),
        'component': IndividualReport,
        'id': 'current-trade-status',
        'resourceName': 'cts.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_CTTF_ROUTE),
        'component': IndividualReport,
        'id': 'current-trade-totals',
        'resourceName': 'cttf.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_WS_ROUTE),
        'component': IndividualReport,
        'id': 'wire-settlement',
        'resourceName': 'ws.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_HTAE_ROUTE),
        'component': IndividualReport,
        'id': 'trade-approval-exceptions',
        'resourceName': 'htae.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCTA_ROUTE),
        'component': IndividualReport,
        'id': 'debit-credit-transactions',
        'resourceName': 'dcta.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCSS_ROUTE),
        'component': IndividualReport,
        'id': 'debit-credit-settlement',
        'resourceName': 'dcss.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_DCFS_ROUTE),
        'component': IndividualReport,
        'id': 'debit-credit-activity',
        'resourceName': 'dcfs.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_FB_ROUTE),
        'component': IndividualReport,
        'id': 'fund-balances',
        'resourceName': 'fb.new'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.REPORT_RF_ROUTE),
        'component': IndividualReport,
        'id': 'fund-rates-factors',
        'resourceName': 'rf.new'
      }
    ]
  },
  {
    'exact': true,
    'path': getAppRoute(Constants.FUND_FINDER_ROUTE),
    'component': FundFinder,
    'id': 'FundFinder',
    'resourceName': 'FUNDFINDER'
  },
  {
    'exact': true,
    'path': getAppRoute(Constants.TRADING_ROUTE),
    'component': Trading,
    'id': 'Trading',
    'resourceName': 'TRADING'
  },
  {
    'path': getAppRoute(Constants.FUND_TRACKER_ROUTE),
    'component': FundTrackerMain,
    'id': 'FundTracker',
    'skipUpdatePageId': true,
    'subRoutes': [
      {
        'exact': true,
        'path': getAppRoute(Constants.FUND_TRACKER_NAV),
        'component': FundTrackerNew,
        'id': 'Nav',
        'resourceName': 'FUNDTRACKERNEW'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.FUND_TRACKER_PERFORMANCE),
        'component': FundTrackerNew,
        'id': 'Performance',
        'resourceName': 'FUNDTRACKERNEW'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.FUND_TRACKER_LIQUIDITY),
        'component': FundTrackerNew,
        'id': 'Liquidity',
        'resourceName': 'FUNDTRACKERNEW'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.FUND_TRACKER_DURATION),
        'component': FundTrackerNew,
        'id': 'Duration',
        'resourceName': 'FUNDTRACKERNEW'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.FUND_TRACKER_ASSETS),
        'component': FundTrackerNew,
        'id': 'Assets',
        'resourceName': 'FUNDTRACKERNEW'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.FUND_TRACKER_MY_HOLDINGS),
        'component': FundTrackerNew,
        'id': 'MyHoldings',
        'resourceName': 'FUNDTRACKERNEW'
      },
      {
        'path': getAppRoute(Constants.ERROR_ROUTE),
        'exact': true,
        'component': Error,
        'id': 'Error'
      }
    ]
  },
  {
    'exact': true,
    'path': getAppRoute(Constants.USER_SEARCH_ROUTE),
    'component': UserSearch,
    'id': 'UserSearch',
    'resourceName': 'SELFSERVICEADMIN'
  },
  {
    'path': getAppRoute(Constants.TRADE_ACTIVITY_ROUTE),
    'component': TradeActivity,
    'id': 'TradeActivity',
    'skipUpdatePageId': false,
    'subRoutes': [
      {
        'exact': true,
        'path': getAppRoute(Constants.TRADE_ACTIVITY_APPROVALS),
        'component': TradeApprovals,
        'id': 'TradeApproval',
        'resourceName': 'TRADEAPPROVAL'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.TRADE_CURRENT_TRADE_STATUS),
        'component': CurrentTrades,
        'id': 'CurrentTradeStatus',
        'resourceName': 'CTS'
      },
      {
        'path': getAppRoute(Constants.ERROR_ROUTE),
        'exact': true,
        'component': Error,
        'id': 'Error'
      }
    ]
  },
  {
    'path': getAppRoute(Constants.PREFERENCES_ROUTE),
    'component': Preferences,
    'id': 'Preferences',
    'skipUpdatePageId': true,
    'subRoutes': [
      {
        'exact': true,
        'path': getAppRoute(Constants.PREFERENCES_DISPLAY_SETTINGS_ROUTE),
        'component': DisplaySettings,
        'id': 'DisplaySettings'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.PREFERENCES_EMAIL_CONFIGURATION_ROUTE),
        'component': EmailNotifications,
        'id': 'EmailNotifications'
      },
      {
        'exact': true,
        'path': getAppRoute(Constants.PREFERENCES_FUND_TRACKER_THRESHOLDS_ROUTE),
        'component': FundTrackerThresholds,
        'id': 'FundTrackerThresholds'
      },
      {
        'path': getAppRoute(Constants.PREFERENCES_INVESTMENT_POLICY_ROUTE),
        'component': InvestmentPolicy,
        'id': 'InvestmentPolicy',
        'subRoutes': [
        ]
      },
      {
        'path': getAppRoute(Constants.ERROR_ROUTE),
        'exact': true,
        'component': Error,
        'id': 'Error'
      }
    ]
  },
  {
    'exact': true,
    'path': getAppRoute(Constants.FAQ_ROUTE),
    'component': FAQ,
    'resourceName': 'FAQ',
    'id': 'FAQ'
  },
  {
    'exact': true,
    'path': getAppRoute(Constants.GLOSSARY_ROUTE),
    'component': Glossary,
    'resourceName': 'FAQ',
    'id': 'Glossary'
  },
  {
    'exact': true,
    'path': getAppRoute(Constants.BUILD_INFO_ROUTE),
    'component': BuildInfo,
    'id': 'BuildInfo'
  },
  {
    'exact': true,
    'path': getAppRoute(Constants.LOGOUT_ROUTE),
    'component': Logout,
    'id': 'Logout'
  },
  {
    'path': getAppRoute(Constants.ERROR_ROUTE),
    'exact': true,
    'component': Error,
    'id': 'Error'
  }
];
